import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import CentreEtatCivil from "./composants/centreEtatCivil";
import CreatSession2 from "./composants/certificat/creatSessions2";
import MotifLegal2 from "./composants/certificat/motifLegale2";
import NumLegal from "./composants/certificat/numLegal";
import Otp2 from "./composants/certificat/otp2";
import PaymentPage2 from "./composants/certificat/paymentPage2";
import PreuvePaiement2 from "./composants/certificat/preuvePaiement2";
import ConfirmValide from "./composants/confirmValide";
import Contact from './composants/contact';
import CreatSession from "./composants/creatSession";
import Home from './composants/home';
import InfoParents from "./composants/infoParents";
import InfoPersonnel from "./composants/infoPersonnel";
import MaDemande1 from "./composants/maDemande1";
import MaDemande2 from "./composants/maDemande2";
import MotifLegal from "./composants/motifLegale";
import Otp from "./composants/otp";
import PaymentPage from "./composants/PaymentPage";
import PreuvePaiement from "./composants/preuvePaiement";
import Upload from "./composants/upload";
import Navbarre from "./sousComposents/navbarre";

function App() {

  const [currentRoute, setcurrentRoute] = useState();
  const location = useLocation();

  useEffect(() => {
    const path = window.location.pathname.toLocaleLowerCase();
    //console.log(path.slice(1, path.length));
    setcurrentRoute(path.slice(1, path.length));
  }, [location]);


  return (
    <>
      <Navbarre setcurrentRoute={setcurrentRoute} currentRoute={currentRoute} />
      <Routes>
        <Route path="/" element={<Home setcurrentRoute={setcurrentRoute} />} />
        <Route path="/contact" element={<Contact setcurrentRoute={setcurrentRoute} />} />
        <Route path="/mademande1" element={<MaDemande1 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/mademande2" element={<MaDemande2 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/session" element={<CreatSession setcurrentRoute={setcurrentRoute} />} />
        <Route path="/otp" element={<Otp setcurrentRoute={setcurrentRoute} />} />
        <Route path="/centre" element={<CentreEtatCivil setcurrentRoute={setcurrentRoute} />} />
        <Route path="/infoperso" element={<InfoPersonnel setcurrentRoute={setcurrentRoute} />} />
        <Route path="/infoparents" element={<InfoParents setcurrentRoute={setcurrentRoute} />} />
        <Route path="/paymentpage" element={<PaymentPage setcurrentRoute={setcurrentRoute} />} />
        <Route path="/confirmvalide" element={<ConfirmValide setcurrentRoute={setcurrentRoute} />} />
        <Route path="/upload" element={<Upload setcurrentRoute={setcurrentRoute} />} />
        <Route path="/paymentpage" element={<PaymentPage setcurrentRoute={setcurrentRoute} />} />
        <Route path="/preuvepaiement" element={<PreuvePaiement setcurrentRoute={setcurrentRoute} />} />
        <Route path="/motiflegal" element={<MotifLegal setcurrentRoute={setcurrentRoute} />} />
        {/* ..............................Certificat de nationnalité........................................... */}
        <Route path="/session2" element={<CreatSession2 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/otp2" element={<Otp2 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/motiflegal2" element={<MotifLegal2 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/numlegal" element={<NumLegal setcurrentRoute={setcurrentRoute} />} />
        <Route path="/paymentpage2" element={<PaymentPage2 setcurrentRoute={setcurrentRoute} />} />
        <Route path="/preuvepaiement2" element={<PreuvePaiement2 setcurrentRoute={setcurrentRoute} />} />
      </Routes>
    </>
  );
}

export default function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
