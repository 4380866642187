import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/infos.css';
import Langue from '../sousComposents/langue';

function CentreEtatCivil({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Entrez les Informations de votre Acte de naissances</p>
                </div>
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">2</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">3</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">4</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">5</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-5 d-flex justify-content-center'>
                <Link to="/centre" onClick={() => setcurrentRoute('centre')}>
                    <button className='bou btn btn-success fw-bold m-1'>Informations centre Etat civil</button>
                </Link>
                <Link to="/infoperso" onClick={() => setcurrentRoute('infoperso')}>
                    <button className='bou btn btn-outline-success fw-bold m-1' id='bou2'>Information personnelles</button>
                </Link>
                <Link to="/infoparents" onClick={() => setcurrentRoute('infoparents')}>
                    <button className='bou btn btn-outline-success fw-bold m-1' id='bou3'>Informations parentales </button>
                </Link>
            </div>

            <div className=' mt-5 mx-1'>
                <p className="text-center fw-bold p1">Informations de centre Etat civil </p>
                <p className="text-center p1">Veillez entrer vos informations de centre d'Etat civil ci-dessous </p>
                <p className="text-center fw-semibold p1">NUMERO DE LA DEMANDE : PO-20241009-000250 </p>
            </div>
            <div className='container col-lg-6 mt-5'>
                <p className='text-start fw-medium text-secondary'>Centre d’Etat Civil	</p>
                <form className='d-flex flex-column mb-5'>
                    <label className='form-lable fw-bold p1'>Reference du Centre d’Etat Civil </label>
                    <input type="text" className="form-control" placeholder='Renseignez  la reference de votre Centre d’Etat Civil' />
                    <label className='form-lable mt-3 p1'>Acte de Naissance 𝑁 </label>
                    <input type="text" className="form-control" />
                    <label className='form-lable p1'>Drésser le</label>
                    <input type="text" className="form-control" />
                    <label className='form-lable p1'>Sur la declaration de</label>
                    <input type="text" className="form-control" />
                    <label className='form-lable p1'>Numero de declaration de Naissance </label>
                    <input type="text" className="form-control" />
                    <label className='form-lable p1'>Officier d’Etat Civil</label>
                    <input type="text" className="form-control" />
                    <label className='form-lable p1'>Secrétaire d’Etat Civil</label>
                    <input type="text" className="form-control" />
                    <div className='d-flex justify-content-between'>
                        <div className='mt-3 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('otp')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/otp'}>Précédent
                            </Link></div>
                        <div type="submit" className='mt-5 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('infoperso')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/infoperso'}>Suivant
                            </Link></div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CentreEtatCivil