import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../css/confirmValide.css'
import Langue from '../sousComposents/langue'

function ConfirmValide({ setcurrentRoute }) {
    const navigate = useNavigate()
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Veuillez vérifier et confirmer vos informations </p>
                    <p className='text-success fw-medium m-1'>Vous pouvez modifier les informations incorrectes</p>
                </div>
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">3</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">4</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">5</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <form>
                <div className=' mt-5 mx-1'>
                    <p className="text-center fw-semibold p1">NUMERO DE LA DEMANDE : PO-20241009-000250 </p>
                    <p className="text-center fw-bold p1">Informations de centre Etat civil</p>
                    <p className="text-center p1">Veuillez vérifier vos informations de centre d'Etat civil ci-dessous</p>
                </div>
                <div className='container col-lg-9 mt-5'>
                    <p className='text-start fw-medium text-secondary'>Centre d’Etat Civil	</p>
                    <div className='d-flex flex-column mb-5'>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label fw-bold p1 mt-auto col-lg-4'>Reference du Centre d’Etat Civil :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label mt-3 p1 mt-auto col-lg-4'>Acte de Naissance 𝑁 :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Drésser le :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Sur la declaration de :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Numero de declaration de Naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Officier d’Etat Civil :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Secrétaire d’Etat Civil :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                    </div>
                </div>
                <div className=' mt-5 mx-1'>
                    <p className="text-center fw-bold p1">Informations personnelles</p>
                    <p className="text-center p1">Veuillez Vérifier vos informations personnelles ci-dessous</p>
                </div>
                <div className='container col-lg-9 mt-5'>
                    <p className='text-start fw-medium text-secondary'>Données personnelles</p>
                    <div className='d-flex flex-column mb-5'>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label mt-3 p1 mt-auto col-lg-4'>Prénom :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Nom(s) :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Date de naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Pays de naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Région de naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Département de naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Arrondissement :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Lieu de naissance :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                    </div>
                </div>
                <div className=' mt-5 mx-1'>
                    <p className="text-center fw-bold p1">Informations des parents</p>
                    <p className="text-center p1">Veuillez vérifier les informations sur vos parents ci-dessous</p>
                </div>
                <div className='container col-lg-9 mt-5'>
                    <p className='text-start fw-medium text-secondary'>Informations des parents</p>
                    <div className='d-flex flex-column mb-5'>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label mt-3 p1 mt-auto col-lg-4 fw-bolder'>Nom(s) et Prénom(s) du père :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Date et lieu de Naissance :</label>
                            <div className='d-flex'>
                                <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                                <p className='fw-bolder'>à</p>
                                <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                            </div>
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Proféssion :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label mt-3 p1 mt-auto col-lg-4 fw-bolder'>Nom(s) et Prénom(s) de la mère :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Date et lieu de Naissance :</label>
                            <div className='d-flex'>
                                <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                                <p className='fw-bolder'>à</p>
                                <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                            </div>
                        </div>
                        <div className='d-lg-flex my-2'>
                            <label className='form-label p1 mt-auto col-lg-4'>Proféssion :</label>
                            <input type="text" className="form-control bg-transparent rounded rounded-0 " />
                        </div>
                    </div>
                </div>
                <div className='container col-lg-9 mb-5 d-flex justify-content-between'>
                    <div className='mt-3 align-self-end fw-bold'>
                        <Link onClick={() => {
                            setcurrentRoute('infoparents')
                        }}
                            className="btn btn-outline-success text-decoration-none"
                            to={'/infoparents'}>Précédent
                        </Link></div>
                    <div type="submit" className='mt-5 align-self-end fw-bold btn btn-outline-success text-decoration-none'
                        onClick={() => {
                            const result = window.confirm('Etes-vous sur de vouloir valider ces informations ?')
                            if (result) {
                                setcurrentRoute('upload')
                                navigate('/upload')
                            }
                        }}>
                        Suivant</div>
                </div>
            </form>
        </div>
    )
}

export default ConfirmValide