import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Langue from '../../sousComposents/langue'

function NumLegal({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                {/* <div className="col-10 mt-3">
                <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button>
                <button className='btn btn-outline-success fw-bold m-1'>Se connecter</button>
            </div> */}
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">2</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">3</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' mt-5'>
                <p className="text-center fw-bold pp">Veuillez renseigner le numéro de légalisation de votre acte de naissance</p>
            </div>
            <div className='container col-lg-6 mt-5'>
                <form className='d-flex flex-column'>
                    <div className=''>
                        <div className="">
                            <input
                                className="form-control"
                                type="text"
                                name="phoneNumber"
                                placeholder="Entrer le numéro de légalisation d'acte de naissance"
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='mt-3 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('motiflegal2')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/motiflegal2'}>Précédent
                            </Link></div>
                        <div type="submit" className='mt-5 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('paymentpage2')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/paymentpage2'}>Suivant
                            </Link></div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NumLegal
