import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/infos.css';
import Langue from '../sousComposents/langue';

function InfoPersonnel({ setcurrentRoute }) {
    const [countries, setCountries] = useState([])
    useEffect(() => {
        const endpoint = 'https://restcountries.com/v3.1/all'
        fetch(endpoint)
            .then((reponse) => reponse.json())
            .then((data) => {
                const sortCountries = data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                )
                setCountries(sortCountries)
            })
            .catch((error) => console.error("une erreur s'est produit :" + error))
    }, [])
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Entrez les Informations biographiques</p>
                </div>
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">2</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">3</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">4</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">5</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container mt-5 d-flex justify-content-center'>
                <Link to="/centre" onClick={() => setcurrentRoute('centre')}>
                    <button className='btn succ btn-outline-success fw-bold m-1' id='bou1'>Informations centre Etat civil</button>
                </Link>
                <Link to="/infoperso" onClick={() => setcurrentRoute('infoperso')}>
                    <button className='btn succ btn-success fw-bold m-1'>Informations personnelles</button>
                </Link>
                <Link to="/infoparents" onClick={() => setcurrentRoute('infoparents')}>
                    <button className='btn succ btn-outline-success fw-bold m-1' id='bou3'>Informations parentales </button>
                </Link>
            </div>

            <div className=' mt-5 mx-1'>
                <p className="text-center fw-bold p1">Informations personnelles </p>
                <p className="text-center p1">Veillez entrer vos informations personnelles ci-dessous </p>
                <p className="text-center fw-semibold p1">NUMERO DE LA DEMANDE : PO-20241009-000250 </p>
            </div>
            <div className='container col-lg-6 mt-5'>
                <p className='text-start fw-medium text-secondary'>Données personnelles	</p>
                <form className='d-flex flex-column mb-5'>
                    <div className='m-2'>
                        <label className='form-label mt-3 p1'>Prénom</label>
                        <input type="text" className="form-control" placeholder='Prénom' />
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Nom(s)*</label>
                        <input type="text" className="form-control" placeholder='Nom(s)' />
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Sexe*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez un sexe</option>
                            <option value="male">Masculin</option>
                            <option value="female">Féminin </option>
                        </select>
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Date de Naissance* </label>
                        <input type="date" className="form-control" />
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Pays de Naissance*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez un pays</option>
                            {
                                countries.map((country) => (
                                    <option key={country.cca2} value={country.cca2}>{country.name.common}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Région de Naissance*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez une région</option>
                            <option value="male">Nord</option>
                            <option value="female">Sud </option>
                            <option value="female">Est </option>
                            <option value="female">Ouest </option>
                            <option value="female">NordEst </option>
                            <option value="female">SudEst </option>
                            <option value="female">NordOuest </option>
                            <option value="female">SudOuest </option>
                            <option value="female">Adamaoua </option>
                            <option value="female">Centre </option>
                            <option value="female">Littorale </option>
                        </select>
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Département de Naissance*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez un département</option>
                            <option value="male">Dja et Lobo</option>
                            <option value="female">Mvilla </option>
                        </select>
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Arrondissement*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez un arrondissement</option>
                            <option value="male">Sangmelima</option>
                        </select>
                    </div>
                    <div className='m-2'>
                        <label className='form-label p1'>Lieu de Naissance*</label>
                        <select className="form-control">
                            <option value="">Sélectionnez un lieu</option>
                        </select>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='mt-3 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('centre')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/centre'}>Précédent
                            </Link></div>
                        <div type="submit" className='mt-5 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('infoparents')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/infoparents'}>Suivant
                            </Link></div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InfoPersonnel