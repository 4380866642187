import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../../css/paymentPage.css';
import mtn from '../../images/mtn.png';
import orange from '../../images/orange.png';
import cart from '../../images/paymentmethods.jpg';
import Langue from "../../sousComposents/langue";

const PaymentPage2 = ({ setcurrentRoute }) => {
    const [paymentMethod, setPaymentMethod] = useState("orangeMoney");
    const [formData, setFormData] = useState({
        titulaire: "",
        phoneNumber: "",
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logique de paiement selon la méthode
        if (paymentMethod === "orangeMoney") {
            // Intégrer l'API d'Orange Money
        } else if (paymentMethod === "mobileMoney") {
            // Intégrer l'API Mobile Money
        } else if (paymentMethod === "visa") {
            // Intégrer l'API de paiement Visa
        }
    };

    return (
        <div className="mt-5 pt-5 d-flex flex-column row">
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Veuillez éffectuer le paiement pour soumettre votre demande de certification de votre Certificat de Nationalité </p>

                </div>
                <Langue />
            </div>

            <div className="container mt-3 mb-1"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">3</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-3 mx-1'>
                <p className="text-center fw-semibold p1">NUMERO DE LA DEMANDE : PO-20241009-000250 </p>
                <p className="text-center p1">Veuillez éffectuer le paiement pour valider la demande de certification de votre Certificat de Nationalité</p>
                <p className="text-center fw-bold p1">Détailles nécessaires </p>
            </div>
            <div className=" mt-1 col-11 col-md-4 col-lg-4 align-self-center d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <p className="text-center p1">Timbre fiscale :</p>
                    <p className="text-center p1">1 500fcfa </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-center p1">Service de légalisation :</p>
                    <p className="text-center p1">1 000fcfa </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="text-center fs-4 fw-bolder p1">Total :</p>
                    <p className="text-center fs-4 fw-bolder p1">2 500fcfa </p>
                </div>
                <div className="mt-3 d-flex justify-content-center">
                    <p className="text-center fs-6 fw-bolder p1" style={{ fontStyle: "italic" }}>Veuillez choisir le mode de paiement </p>
                </div>
            </div>
            <div className="container col-lg-8 mx-auto">
                <form onSubmit={handleSubmit}>

                    <div className="d-flex justify-content-around mt-2 flex-wrap">
                        <div className="d-flex flex-column align-items-center">
                            <img src={orange} alt="Orange Money" className="img-count"
                                onClick={() => {
                                    document.getElementById('radio1').click();
                                }} />
                            <label className="form-check-label"
                                onClick={() => {
                                    document.getElementById('radio1').click();
                                }}>
                                Orange Money
                            </label>
                            <input
                                id="radio1"
                                className="form-check-input"
                                type="radio"
                                value="orangeMoney"
                                checked={paymentMethod === "orangeMoney"}
                                onChange={handlePaymentMethodChange}
                            />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <img src={mtn} alt="Mobile Money" className="img-count"
                                onClick={() => {
                                    document.getElementById('radio2').click();
                                }} />
                            <label className="form-check-label"
                                onClick={() => {
                                    document.getElementById('radio2').click();
                                }}>
                                Mobile Money
                            </label>
                            <input
                                id="radio2"
                                className="form-check-input"
                                type="radio"
                                value="mobileMoney"
                                checked={paymentMethod === "mobileMoney"}
                                onChange={handlePaymentMethodChange}
                            />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <img src={cart} alt="Visa Card" className="img-count-cart"
                                onClick={() => {
                                    document.getElementById('radio3').click();
                                }}
                            />
                            <label className="form-check-label"
                                onClick={() => {
                                    document.getElementById('radio3').click();
                                }}
                            >
                                Carte Bancaire
                            </label>
                            <input
                                id="radio3"
                                className="form-check-input"
                                type="radio"
                                value="visa"
                                checked={paymentMethod === "visa"}
                                onChange={handlePaymentMethodChange}
                            />
                        </div>
                    </div>

                    {paymentMethod && (
                        <div className="mt-4">
                            {paymentMethod === "orangeMoney" || paymentMethod === "mobileMoney" ? (
                                <div className="d-flex flex-column align-items-center mt-3 flex-wrap">
                                    <label className="form-label">Numéro de téléphone :</label>
                                    <input
                                        className="form-control champ-money"
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="Entrer votre numéro de téléphone"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-between align-items-center mt-3 mx-3">
                                        <div>
                                            <label className="form-label">Titulaire de la carte:</label>
                                            <input
                                                className="form-control champ-money2"
                                                type="text"
                                                name="cardHolderName"
                                                placeholder="nom et prénom"
                                                value={formData.titulaire}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label">Numéro de carte:</label>
                                            <input
                                                className="form-control champ-money2"
                                                type="text"
                                                name="cardNumber"
                                                placeholder="Entrer le numéro de votre carte"
                                                value={formData.cardNumber}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        <div>
                                            <label className="form-label">Date d'expiration :</label>
                                            <input
                                                className="form-control champ-money2"
                                                type="date"
                                                name="expiryDate"
                                                value={formData.expiryDate}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-between align-items-center mb-3 mx-3">
                                        <div>
                                            <label className="form-label">CVV:</label>
                                            <input
                                                className="form-control champ-money2"
                                                type="text"
                                                name="cvv"
                                                placeholder="03 chiffres"
                                                value={formData.cvv}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                </div>
                            )}
                            <div className='container mb-5 d-flex justify-content-between'>
                                <div className='mt-3 align-self-end fw-bold'>
                                    <Link onClick={() => {
                                        setcurrentRoute('numlegal')
                                    }}
                                        className="btn btn-outline-success text-decoration-none"
                                        to={'/numlegal'}>Précédent
                                    </Link></div>
                                <div type="submit" className='mt-5 align-self-end fw-bold'>
                                    <Link onClick={() => {
                                        setcurrentRoute('preuvepaiement2')
                                    }}
                                        className="btn btn-outline-success text-decoration-none"
                                        to={'/preuvepaiement2'}>Valider
                                    </Link></div>
                            </div>
                        </div>
                    )}

                </form>
            </div>
        </div>
    );
};

export default PaymentPage2;
