import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/creatSession.css';
import Langue from '../../sousComposents/langue';

function Otp2({ setcurrentRoute }) {
    var length = 4
    // État pour stocker chaque chiffre du code OTP
    const [otp, setOtp] = useState(new Array(length).fill(""));

    // Fonction pour gérer la saisie dans les champs OTP
    const handleChange = (e, index) => {
        const value = e.target.value;

        // Met à jour uniquement si l'entrée est un chiffre
        if (/^[0-9]$/.test(value) || value === "") {
            let otpCopy = [...otp];
            otpCopy[index] = value;

            setOtp(otpCopy);

            // Déplacer automatiquement vers le prochain champ si le champ actuel est rempli
            if (value !== "" && index < length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    // Fonction pour soumettre l'OTP
    const handleSubmit = (e) => {
        e.preventDefault();

        // Vérifier si l'OTP est complet
        if (otp.join("").length === length) {
            // onSubmitOtp(otp.join(""));
        } else {
            alert("Veuillez entrer le code complet.");
        }
    };

    // Fonction pour gérer les touches backspace (retour arrière)
    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "" && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                {/* <div className="col-10 mt-3">
                <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button>
                <button className='btn btn-outline-success fw-bold m-1'>Se connecter</button>
            </div> */}
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">1</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">2</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">3</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container col-lg-6 mt-5'>
                <form className='d-flex flex-column' onSubmit={handleSubmit}>
                    <label className='form-label align-self-center text-center'>Veillez entrer le code que vous avez reçu</label>
                    {/* <input type="" className="form-control" placeholder='Entrer le code reçu' /> */}
                    <div className='mt-4 d-flex flex-column'>
                        <div className="otp-inputs d-flex">
                            {otp.map((data, index) => (
                                <input
                                    type="text"
                                    key={index}
                                    id={`otp-input-${index}`}
                                    maxLength="1"
                                    value={data}
                                    className="otp-input form-control m-1"
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                />
                            ))}
                        </div>
                        <button className="btn renv mt-4 align-self-center">
                            Renvoyer le code
                        </button>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='mt-3 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('session2')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/session2'}>Précédent
                            </Link></div>
                        <div type="submit" className='mt-5 align-self-end fw-bold'>
                            <Link onClick={() => {
                                setcurrentRoute('motiflegal2')
                            }}
                                className="btn btn-outline-success text-decoration-none"
                                to={'/motiflegal2'}>Suivant
                            </Link></div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Otp2