import React from 'react'
import Langue from '../sousComposents/langue'
import Retour from '../sousComposents/retour'

function MaDemande2({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5 ms-lg-5 d-flex flex-column'>
            <div className="container m-auto row">
                <Retour setcurrentRoute={setcurrentRoute} />
                <Langue />
            </div>
            <div className='align-self-center d-flex flex-column align-items-center'>
                <h2 className='fw-bold fs-5 mb-5 mt-3 mx-3 text-center' style={{ color: "#0c235f" }}>Aucun document disponible</h2>
                <p className='text-start fw-medium text-secondary mt-5 mx-3'>Demande en cours de traitement ...</p>

            </div>
        </div>
    )
}

export default MaDemande2