import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons/faCircleArrowLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function Retour({ setcurrentRoute }) {
    return (
        <>
            <Link onClick={() => {
                setcurrentRoute("home")
            }}
                className="text-decoration-none col-10"
                to={'/'}>
                <div className="fs-1" style={{ color: "#496fcf" }} >
                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                </div>
            </Link>
        </>
    )
}

export default Retour