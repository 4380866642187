import React from 'react';
import '../css/preuvePaiement.css';
import logo from '../images/logo.jpg';
import Langue from '../sousComposents/langue';

function PreuvePaiement({ setcurrentRoute }) {
    return (
        <div className="mt-5 pt-5 d-flex flex-column row">
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Ici votre preuve de paiement </p>
                    <p className='text-success fw-medium m-1'>Vous pouvez la télécharger ici ou sur l’onglet <strong>Ma Demande</strong> </p>

                </div>
                <Langue />
            </div>

            <div className="card card-hover mx-auto mt-5 mb-4" style={{ width: "30rem" }}>
                <div className="card-body">
                    <div className='d-flex justify-content-around align-items-center mb-3'>
                        <img src={logo} className="card-img-top h-25 w-25" alt="logo" />
                        <h5 className="card-title preuve-title">Preuve de Paiement</h5>
                    </div>
                    <h6 className="card-subtitle mb-4 text-body-secondary commande-preuve fs-6 text-center">NUMERO DE LA DEMANDE : PO-20241009-000250 </h6>
                    <div className='d-flex justify-content-between'>
                        <p className="card-text text-center text-preuve fw-bolder">Nom et Prénom :</p>
                        <p className="card-text text-center text-preuve fw-medium">Ndong Medjo Daniel Phanuel</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className="card-text text-center text-preuve fw-bolder">Date :</p>
                        <p className="card-text text-center text-preuve fw-medium">17/10/2024</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className="card-text text-center text-preuve fw-bolder">Montant :</p>
                        <p className="card-text text-center text-preuve fw-medium">3 000fcfa</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className="card-text text-center text-preuve fw-bolder">Mode de paiement :</p>
                        <p className="card-text text-center text-preuve fw-medium">Orange Money</p>
                    </div>

                </div>
            </div>

            <div className='align-self-center mx-auto d-flex justify-content-center'>
                <button className='btn btn-outline-light btn-preuve mx-5 mb-5' style={{ backgroundColor: "#5a7bcf" }}>Retour</button>
                <button className='btn btn-outline-light btn-preuve mx-5 mb-5' style={{ backgroundColor: "#5a7bcf" }}>Télécharger (pdf)</button>
            </div>

            {/* <div className='container mb-5 d-flex justify-content-between'>
                <div className='mt-3 align-self-end fw-bold'>
                    <Link href="#home" onClick={() => {
                        setcurrentRoute('upload')
                    }}
                        className="btn btn-outline-success text-decoration-none"
                        to={'/upload'}>Précédent
                    </Link></div>
                <div type="submit" className='mt-5 align-self-end fw-bold'>
                    <Link href="#home" onClick={() => {
                        setcurrentRoute('preuvePaiement')
                    }}
                        className="btn btn-outline-success text-decoration-none"
                        to={'/preuvePaiement'}>Valider
                    </Link></div>
            </div> */}
        </div>
    );
}

export default PreuvePaiement