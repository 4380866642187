import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../css/navbar.css';
function Navbarre({ ...props }) {
    return (
        <Navbar expand="lg" className='navbar fixed-top navbar-expand-lg  d-flex justify-content-end m-1 p-3 '>
            <Container className="nav na-pills pe-2">
                <Navbar.Toggle className="bg-light mb-1" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Link onClick={() => {
                            props.setcurrentRoute('contact')
                        }}
                            className={
                                props.currentRoute === "contact"
                                    ? "btn btn-light ms-2 mt-2 fw-bolder"
                                    : "btn btn-outline-light mt-2 ms-2 fw-bolder"
                            }

                            to={'/contact'}>Contact</Link>
                        <Link onClick={() => {
                            props.setcurrentRoute('mademande1')
                        }}
                            className={
                                props.currentRoute === "mademande1" || props.currentRoute === "mademande2"
                                    ? "btn btn-light ms-2 mt-2 fw-bolder "
                                    : "btn btn-outline-light ms-2 mt-2 fw-bolder "
                            }

                            to={'/mademande1'}>Ma Demande</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navbarre