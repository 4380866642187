import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/creatSession.css';
import Langue from '../../sousComposents/langue';
function CreatSession2({ setcurrentRoute }) {
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                {/* <div className="col-10 mt-3">
                <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button>
                <button className='btn btn-outline-success fw-bold m-1'>Se connecter</button>
            </div> */}
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">1</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">2</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">3</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' mt-5'>
                <p className="text-center fw-bold pp">Créer une session de demande de Certificat de Nationalité </p>
            </div>
            <div className='container col-lg-6 mt-5'>
                <form className='d-flex flex-column'>
                    <input type="text" className="form-control" placeholder='Entrer votre mail/Numéro de téléphone' />
                    <div className='mt-5 align-self-end fw-bold'>
                        <Link onClick={() => {
                            setcurrentRoute('otp2')
                        }}
                            className="btn btn-outline-success text-decoration-none"
                            to={'/otp2'}>Suivant
                        </Link></div>
                </form>
            </div>
        </div>
    )
}

export default CreatSession2