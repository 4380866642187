import { faCircleCheck, faCloudArrowUp, faImage, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../css/upload.css'
import Langue from '../sousComposents/langue'

function Upload({ setcurrentRoute }) {
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('Auccun fichier sélectionné')
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                <div className="col-10 mt-3">
                    {/* <button className='btn btn-outline-success fw-bold m-1'>Nouvelle Demmande</button> */}
                    <p className='text-success fw-bold m-1'>Veuillez télécharger l’image ou le pdf de l’original de votre acte de naissance </p>
                    <p className='text-success fw-medium m-1'>L’image ou le document pdf doit être clairement visible</p>
                </div>
                <Langue />
            </div>

            <div className="container mt-5 mb-3"
                data-wow-delay="1.25s">
                <div className="lesboules">
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule text-success border border-success"><FontAwesomeIcon icon={faCircleCheck} /></div>
                            <div className="corde bg-success"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule-app">4</div>
                            <div className="corde"></div>
                        </div>
                    </div>
                    <div className="comp-boule">
                        <div className="d-flex justify-content-center align-items-center align-content-center">
                            <div className="boule">5</div>
                            <div className="corde6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-5 mx-1'>
                <p className="text-center fw-semibold p1">NUMERO DE LA DEMANDE : PO-20241009-000250 </p>
                <p className="text-center fw-bold p1">Télécharger votre acte de naissance</p>
                <p className="text-center p1">Veuillez télécharger l’image ou le document pdf de l’original de votre acte de naissance </p>
            </div>
            <form className='mt-3'>
                <div className='container col-lg-9 mt-5 d-flex flex-column justify-content-center' id='img-upload' onClick={() => {
                    document.getElementById('exampleFormControlFile1').click()
                }}>
                    <input type="file" className="form-control-file align-self-center" id="exampleFormControlFile1" hidden
                        onChange={({ target: { files } }) => {
                            files[0] && setFileName(files[0].name)
                            files && setFile(URL.createObjectURL(files[0]))
                        }}
                    />
                    {
                        file ? <img className='align-self-center file-upload' src={file} height={60} width={60} alt={fileName} />
                            :
                            <div className='align-self-center d-flex flex-column'>
                                <div className="align-self-center icon-upload"><FontAwesomeIcon icon={faCloudArrowUp} /></div>
                                <h3 className='align-self-center text-upload'>Veuillez sélectionner le fichier</h3>
                            </div>
                    }
                </div>
                <div className='container col-lg-9 mt-2 d-flex justify-content-between' id='barre-upload'>
                    <div className="align-self-center icon-barre-upload"><FontAwesomeIcon icon={faImage} /></div>
                    <h3 className='align-self-center text-barre-upload'>{fileName}</h3>
                    <div className="align-self-center icon-barre-upload" onClick={() => {
                        setFile(null)
                        setFileName('Aucun fichier sélectionné')
                        document.getElementById('exampleFormControlFile1').value = null
                    }} ><FontAwesomeIcon icon={faTrashCan} /></div>
                </div>
                <div className='container col-lg-9 mb-5 d-flex justify-content-between'>
                    <div className='mt-3 align-self-end fw-bold'>
                        <Link onClick={() => {
                            setcurrentRoute('confirmvalide')
                        }}
                            className="btn btn-outline-success text-decoration-none"
                            to={'/confirmvalide'}>Précédent
                        </Link></div>
                    <div type="submit" className='mt-5 align-self-end fw-bold'>
                        <Link onClick={() => {
                            setcurrentRoute('paymentpage')
                        }}
                            className="btn btn-outline-success text-decoration-none"
                            to={'/paymentpage'}>Valider
                        </Link></div>
                </div>
            </form>
        </div>
    )
}

export default Upload