import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { Link } from "react-router-dom";
import '../css/home.css';
import acte from '../images/act.png';
import certificat from '../images/certified.png';
import casier from '../images/license.png';
import logo from '../images/logo.jpg';
import Langue from "../sousComposents/langue";
function Home({ ...props }) {
    return (
        <div className='mt-5 pt-5'>
            <div className="container m-auto row">
                <div className="col-2">
                    <img className="img" src={logo} alt='Logo' />
                </div>
                <div className="col-8 mt-2 d-flex flex-column justify-content-center align-items-center align-content-center">
                    <h1 className="display-6">Bienvenue</h1>
                    <p className="p1">Sur</p>
                </div>
                <Langue />
            </div>
            <div>
                <h1 className="text-center fw-bold ANGARA">ANGARA AUTHENTIC</h1>
                <p className="text-center p1">Votre portail de demande de copie certifier conforme de document officiel </p>
            </div>
            <div className="container mx-auto mt-5 pt-3 doc">

                <Link onClick={() => {
                    props.setcurrentRoute('session')
                }}
                    className="text-decoration-none"
                    to={'/session'}>
                    <div className="card pt-3 d-flex flex-column justify-content-center align-items-center" style={{ width: '8rem' }} >
                        <img src={acte} className="card-img-top img2" alt="logo2" />
                        <div className="card-body">
                            <h4 className="card-text fs-6 fw-bolder text-center">
                                Actes de Naissances
                            </h4>
                        </div>
                    </div>
                </Link>

                <Link onClick={() => {
                    props.setcurrentRoute('session2')
                }}
                    className="text-decoration-none"
                    to={'/session2'}>
                    <div className="card pt-3 d-flex flex-column justify-content-center align-items-center" style={{ width: '8rem' }} >
                        <img src={certificat} className="card-img-top img2" alt="logo2" />
                        <div className="card-body">
                            <h4 className="card-text fs-6 fw-bolder text-center">
                                Certificat de
                                Nationalité
                            </h4>
                        </div>
                    </div>
                </Link>

                <div className="card pt-3 d-flex flex-column justify-content-center align-items-center" style={{ width: '10rem' }}>
                    <img src={casier} className="card-img-top img2" alt="logo2" />
                    <div className="card-body">
                        <h4 className="card-text fs-6 fw-bolder text-center">
                            Extrait du Casier
                            Judiciaire
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home